import _ from 'lodash'
import {
  convertPreset,
  fetchPreset,
  enhanceConfigByRole,
  enhanceStructreWithSnapshot,
  getFormControllerType,
} from '../services/form-service'
import { createSuffixedName } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { addToHistory, absorbException } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import { FIELDS, ROLE_FORM } from '../../../constants/roles'
import CoreApi from '../core-api'
import { APP_WIDGET_DEFINITION } from './controller-definition'
import { PRESET_TYPES, RESPONSIVE_PRESET_TYPES } from './constants'
import { findPlugin } from '../plugins/utils'
import Experiments from '@wix/wix-experiments'
import { FedopsLogger } from '@wix/fedops-logger'
import {
  COMPONENT_TYPES,
  FormPlugin,
  LIGHTBOX_PRESETS,
  STRIPS_PRESETS,
  BillingPanelReferrer,
  UpgradeAlertType,
  NOTIFICATION_EVENTS,
} from '@wix/forms-common'
import { convertContactFormToWixForms, isSupported } from '../services/contact-form-service'
import RemoteApi from '../../../panels/commons/remote-api'

const normalizeFormName = (
  formNames: string[],
  nameFromPreset: string | undefined,
  presetKey: FormPresetName,
) => {
  const title =
    nameFromPreset ||
    translations.t('formName', {
      name: translations.t(`addForm.templates.${presetKey}.label`),
    })

  return createSuffixedName(formNames, title)
}

const addedFormsPromisesContainer = {}

export interface AddFormPayload {
  containerRef?: ComponentRef
  targetPageRef?: ComponentRef
  source?: string
  shouldSelectForm?: boolean
  createCollection?: boolean
  width?: number
  space?: number
}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments
  private ravenInstance
  private fedopsLogger: FedopsLogger
  private remoteApi: RemoteApi

  constructor(
    boundEditorSDK,
    coreApi,
    remoteApi: RemoteApi,
    { biLogger, experiments, ravenInstance, fedopsLogger },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.fedopsLogger = fedopsLogger
    this.remoteApi = remoteApi
  }

  public addForm(preset: FormPresetName, payload?: AddFormPayload, formSnapshot?: FormSnapshot) {
    return this._addForm(preset, payload, formSnapshot)
  }

  public async addAppWidget(
    pageRef: ComponentRef | PageRef,
    containerDefinition,
  ): Promise<ComponentRef> {
    const dataItemIdPlaceholder = 'data_item_id_placeholder'

    const container = _.merge({}, containerDefinition.data, {
      components: _.get(containerDefinition, 'data.components') || [],
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            role: ROLE_FORM,
            controllerId: dataItemIdPlaceholder,
            isPrimary: true,
            config: JSON.stringify(containerDefinition.connectionConfig),
          },
        ],
      },
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: <any>_.merge({}, APP_WIDGET_DEFINITION, {
        data: {
          id: dataItemIdPlaceholder,
          controllerType: 'wixForms',
        },
        layout: containerDefinition.data.layout,
        components: [container],
      }),
      pageRef,
    })
  }

  public updateFormCollectionIdADI(formCompRef: ComponentRef, oldCollectionId?: string) {
    if (!oldCollectionId) {
      return
    }
    const compIdAndRealCollectionId = oldCollectionId.split('_')
    if (compIdAndRealCollectionId.length <= 1) {
      return
    }
    const realCollectionId = compIdAndRealCollectionId[1]
    const newCollectionId = `${formCompRef.id}_${realCollectionId}`
    return this.coreApi.setComponentConnection(formCompRef, { collectionId: newCollectionId })
  }

  public waitForAddedForm(formCompRef: ComponentRef): Promise<undefined> {
    return addedFormsPromisesContainer[formCompRef.id]
  }

  public async createAutoCollection(componentRef: ComponentRef): Promise<string> {
    this.fedopsLogger.interactionStarted('create-auto-collection')
    await this.coreApi.saveSite()
    const res = await this.coreApi.createAutoCollection(componentRef)
    this.fedopsLogger.interactionEnded('create-auto-collection')
    return res
  }

  private _saveSiteIfNecessary(formRef: ComponentRef): Promise<null> {
    const installedFromAppMarket = !!!formRef // we want to manual save only for app market installation
    return installedFromAppMarket ? this.coreApi.saveSiteIfUnsaved() : Promise.resolve()
  }

  private async _runPostAddFormBasedPluginBehavior({
    selectForm,
    controllerRef,
    formRef,
    targetPageRef,
  }: {
    selectForm: boolean
    controllerRef: ComponentRef
    formRef: ComponentRef
    targetPageRef: ComponentRef | null
  }) {
    const {
      config: { plugins },
    } = await this.coreApi.getComponentConnection(formRef)

    if (selectForm) {
      this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [controllerRef],
      })
    }

    if (findPlugin(plugins, FormPlugin.REGISTRATION_FORM) && targetPageRef) {
      // TODO: Move this to reg form plugin app when ready
      const lightboxContainerRef = await this._findChildCompByType(
        targetPageRef,
        COMPONENT_TYPES.LIGHTBOX_CONTAINER,
      )

      if (lightboxContainerRef) {
        this.coreApi.layout.alignChildComponentToCenter({
          containerRef: lightboxContainerRef,
          childRef: controllerRef,
        })
        this.boundEditorSDK.selection.selectComponentByCompRef({
          compsToSelect: [lightboxContainerRef],
        })
      }
    }

    if (findPlugin(plugins, FormPlugin.PAYMENT_FORM)) {
      //tslint:disable-line
      this.coreApi.popNotificationAction({
        // TODO: Move to manage panels
        componentRef: formRef,
        plugins,
        notificationTrigger: NOTIFICATION_EVENTS.PAYMENT_FORM_ADDED,
      })
    }

    if (findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)) {
      //tslint:disable-line
      await this.coreApi.steps.updateMultiStepFormTitles(formRef)
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [formRef],
      })
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [controllerRef],
      })
    }
  }

  private async _createFormConfig(
    formConfig: ComponentConfig,
    presetName: FormPresetName,
  ): Promise<ComponentConfig> {
    const [formName, msid] = await Promise.all([
      this._getFormName(_.get(formConfig, 'formName'), presetName),
      this.coreApi.getMetaSiteId(),
    ])
    let formLabelId = ''

    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}

    const initialConfig = {
      ...formConfig,
      formName,
      msid,
      formLabelId,
      emailId: '',
      labels: [...formConfig.labels, formLabelId],
    }
    return this.coreApi.isResponsive() ? { ...initialConfig, useControllerId: true } : initialConfig
  }

  private async _enhanceField(fieldConfig: ComponentConfig): Promise<ComponentConfig> {
    const customFieldId = await this.coreApi.fields.getCustomFieldForField({
      connectionConfig: fieldConfig,
    })
    if (customFieldId) {
      return { ...fieldConfig, customFieldId }
    }

    return fieldConfig
  }

  private async _addFormEditor({
    presetName,
    formRef,
    targetPageRef,
    selectForm = true,
    createCollection = true,
    rawPreset,
  }: {
    presetName: FormPresetName
    formRef: ComponentRef | null
    targetPageRef: ComponentRef | null
    selectForm: boolean
    createCollection: boolean
    rawPreset: RawComponentStructure
  }): Promise<ComponentRef> {
    const installedFromAppMarket = !formRef
    const { structure: formStructure, ancestors } = await this._createFormStructureEditor({
      presetName,
      rawPreset,
      formRef,
    })

    if (!installedFromAppMarket) {
      await this.boundEditorSDK.selection.deselectComponents({ compsToDeselect: [ancestors[0]] })
      await this.boundEditorSDK.components.remove({
        componentRef: ancestors[0],
      })
    }

    const { addedFormRef, controllerRef } = await this._addFormStructureEditor({
      formStructure,
      ancestors,
    })

    this._afterFormAddedEditor({
      controllerRef,
      targetPageRef,
      selectForm,
      addedFormRef,
      createCollection,
    })

    return addedFormRef
  }

  private async _createFormStructureEditor({
    presetName,
    rawPreset,
    formRef,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
    formRef: ComponentRef
  }): Promise<{ structure: ComponentStructure; ancestors: ComponentRef[] }> {
    const structure = await this._createDynamicFormDataEditor({ presetName, rawPreset })
    const ancestors = formRef
      ? await this.boundEditorSDK.components.getAncestors({
          componentRef: formRef,
        })
      : []

    const appWidgetStructure = formRef
      ? await this.boundEditorSDK.components.serialize({
          componentRef: ancestors[0],
        })
      : _.merge({}, APP_WIDGET_DEFINITION, {
          layout: { x: 175, y: 0 },
          data: { controllerType: getFormControllerType(structure) },
        })

    return {
      structure: convertPreset(structure, {
        controllerId: 'placeholder-id',
        appWidgetStructure,
        coords: _.pick(appWidgetStructure.layout, ['x', 'y']),
      }),
      ancestors,
    }
  }

  private async _createDynamicFormDataEditor({
    presetName,
    rawPreset,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
  }): Promise<RawComponentStructure> {
    const roleEnhancmentMap = {
      [ROLE_FORM]: config => this._createFormConfig(config, presetName),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _addFormStructureEditor({
    formStructure,
    ancestors,
  }: {
    formStructure: ComponentStructure
    ancestors: ComponentRef[]
  }): Promise<{ addedFormRef: ComponentRef; controllerRef: ComponentRef }> {
    const pageRef = ancestors[1] || (await this.boundEditorSDK.pages.getCurrent())
    const controllerRef = await this.boundEditorSDK.components.add({
      componentDefinition: <any>formStructure,
      pageRef,
    })
    const addedFormRef = (
      await this.boundEditorSDK.components.getChildren({
        componentRef: controllerRef,
      })
    )[0]
    return { addedFormRef, controllerRef }
  }

  private _afterFormAddedEditor({
    controllerRef,
    targetPageRef,
    selectForm,
    addedFormRef,
    createCollection,
  }: {
    controllerRef: ComponentRef
    targetPageRef: ComponentRef | null
    selectForm: boolean
    addedFormRef: ComponentRef
    createCollection: boolean
  }) {
    this.coreApi.appState.setState([controllerRef])

    this._runPostAddFormBasedPluginBehavior({
      selectForm,
      controllerRef,
      formRef: addedFormRef,
      targetPageRef,
    })

    addedFormsPromisesContainer[addedFormRef.id] = new Promise(async resolve => {
      try {
        if (createCollection) {
          await this.createAutoCollection(addedFormRef)
          resolve()
        } else {
          await this.coreApi.editDraft(addedFormRef)
          resolve()
        }
      } finally {
        await addToHistory(this.boundEditorSDK)
      }
    })
  }

  private async _addFormADI({
    formRef,
    formSnapshot,
    rawPreset,
    presetName,
    createCustomFields,
    width,
    space,
  }: {
    formRef: ComponentRef
    formSnapshot: FormSnapshot | undefined
    rawPreset: RawComponentStructure
    presetName: FormPresetName
    createCustomFields: boolean
    width: number | undefined
    space: number | undefined
  }): Promise<ComponentRef> {
    const { structure: formStructure, ancestors } = await this._createFormStructureADI({
      presetName,
      rawPreset,
      formRef,
      createCustomFields,
      formSnapshot,
      width,
      space,
    })

    const { addedFormRef } = await this._addFormStructureADI({
      formStructure,
      ancestors,
    })

    await this.boundEditorSDK.components.remove({
      componentRef: formRef,
    })
    await this._afterFormAddedADI({
      addedFormRef,
      formSnapshot,
    })

    return addedFormRef
  }

  private async _createFormStructureADI({
    presetName,
    rawPreset,
    formRef,
    createCustomFields,
    formSnapshot,
    width,
    space,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
    formRef: ComponentRef
    createCustomFields: boolean
    formSnapshot: FormSnapshot
    width: number | undefined
    space: number | undefined
  }): Promise<{
    structure: ComponentStructure
    ancestors: ComponentRef[]
  }> {
    const structure = await this._createDynamicFormDataADI({
      presetName,
      rawPreset,
      formSnapshot,
      createCustomFields,
    })
    const [ancestors, { x, y }] = await Promise.all([
      this.boundEditorSDK.components.getAncestors({
        componentRef: formRef,
      }),
      this.boundEditorSDK.components.layout.get({ componentRef: formRef }),
    ])

    const appWidgetStructure = _.merge({}, APP_WIDGET_DEFINITION, {
      layout: { x, y },
      data: { controllerType: getFormControllerType(structure) },
    })

    return {
      structure: convertPreset(structure, {
        controllerId: 'placeholder-id',
        appWidgetStructure,
        coords: _.pick(appWidgetStructure.layout, ['x', 'y']),
        width: formSnapshot ? null : width,
        space,
      }),
      ancestors,
    }
  }

  private async _createDynamicFormDataADI({
    presetName,
    rawPreset,
    formSnapshot,
    createCustomFields,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
    formSnapshot: FormSnapshot
    createCustomFields: boolean
  }): Promise<RawComponentStructure> {
    if (formSnapshot && formSnapshot.formComponent) {
      return enhanceStructreWithSnapshot(rawPreset, formSnapshot)
    }
    const roleEnhancmentMap = {
      [ROLE_FORM]: config => this._createFormConfig(config, presetName),
      ..._.reduce(
        FIELDS,
        (acc, role) => {
          acc[role] = config =>
            createCustomFields ? this._enhanceField(config) : Promise.resolve(config)
          return acc
        },
        {},
      ),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _addFormStructureADI({
    formStructure,
    ancestors,
  }: {
    formStructure: Object
    ancestors: ComponentRef[]
  }): Promise<{ addedFormRef: ComponentRef }> {
    const pageRef = ancestors[0]
    const controllerRef = await this.boundEditorSDK.components.add({
      componentDefinition: <any>formStructure,
      pageRef,
    })
    const addedFormRef = (
      await this.boundEditorSDK.components.getChildren({
        componentRef: controllerRef,
      })
    )[0]
    return { addedFormRef }
  }

  private async _afterFormAddedADI({
    formSnapshot,
    addedFormRef,
  }: {
    formSnapshot: FormSnapshot
    addedFormRef: ComponentRef
  }) {
    if (formSnapshot && _.get(formSnapshot, 'formComponent.config.collectionId')) {
      await this.updateFormCollectionIdADI(
        addedFormRef,
        formSnapshot.formComponent.config.collectionId,
      )
    }

    addedFormsPromisesContainer[addedFormRef.id] = new Promise(async resolve => {
      try {
        await this.coreApi.editDraft(addedFormRef)
        resolve()
      } finally {
        await addToHistory(this.boundEditorSDK)
      }
    })
  }

  public async createNewFormName(formRef: ComponentRef, config: ComponentConfig) {
    const { formName, formLabelId, labels, preset } = config
    const newFormName = await this._getFormName(formName, preset)
    const { id: newFormLabelId } = await this.coreApi.createTag(newFormName)
    const newLabels = _.filter(
      [...(labels || []), newFormLabelId],
      labelId => labelId !== formLabelId,
    )

    await this.coreApi.setComponentConnection(formRef, {
      formName: newFormName,
      formLabelId: newFormLabelId,
      labels: newLabels,
    })
  }

  @absorbException()
  public async handleFormPasted(formRef: ComponentRef) {
    const { config } = await this.coreApi.getComponentConnection(formRef)
    await this.createNewFormName(formRef, config)

    if (this.experiments.enabled('specs.crm.FormsEditorCreateCollectionOnFormDuplication')) {
      await this.coreApi.createAutoCollection(formRef)
    }
  }

  public async preventFormAddition(controllerRef?: ComponentRef) {
    let formComponentRef: ComponentRef

    if (controllerRef) {
      try {
        formComponentRef = await this.coreApi.getFormContainerOfAppWidget(controllerRef)
        await this.coreApi.setComponentConnection(formComponentRef, { isDummyForm: true })
      } catch (err) {}
    }

    const esi = await this.coreApi.getEditorSessionId()
    const { ascendPlan } = await this.coreApi.premium.getCurrentAscendPlan()
    this.coreApi.managePanels.openAddFormPremiumBillingPanel(UpgradeAlertType.FORMS_LIMIT, {
      startBi: {
        form_comp_id: _.get(formComponentRef, 'id', null),
        esi,
        origin: BillingPanelReferrer.NUMBER_OF_FORMS_ALERT,
        current_ascend_plan: ascendPlan,
      },
    })

    if (controllerRef) {
      await this.boundEditorSDK.selection.deselectComponents({ compsToDeselect: [controllerRef] })
      await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
      this.coreApi.saveSite()
    }
  }

  public async handleDuplicatedForm({ controllerRef }) {
    if (await this.hasExceededFormsCount({ hasNewFormRef: !!controllerRef })) {
      this.preventFormAddition(controllerRef)
      return
    }

    const formRef: ComponentRef = await this.coreApi.getFormContainerOfAppWidget(controllerRef)
    this.coreApi.reportBiAppWidgetPasted(formRef)

    if (await this.coreApi.isMultiStepForm(formRef)) {
      await Promise.all([
        this.coreApi.steps.updateMultiStepFormTitles(formRef),
        this.coreApi.steps.updateConnectionConfigStepsOrder(formRef),
      ])
    }

    await this.handleFormPasted(formRef)
    await this.coreApi.selectComponent(controllerRef)
  }

  public async hasExceededFormsCount({
    hasNewFormRef,
  }: {
    hasNewFormRef: boolean
  }): Promise<boolean> {
    const [allFormsRefs, { restrictions }] = await Promise.all([
      this.coreApi.getAllFormsRefs({ shouldExcludeSignupForm: true }),
      this.coreApi.premium.getPremiumRestrictions(),
    ])
    const numberOfCurrentForms = hasNewFormRef ? allFormsRefs.length - 1 : allFormsRefs.length
    return (
      _.get(restrictions, 'forms.limit') !== -1 && numberOfCurrentForms >= restrictions.forms.limit
    )
  }

  public async preventFormAdditionAddPanel(
    presetName: FormPresetName,
    newFormRef: ComponentRef,
  ): Promise<boolean> {
    if (
      _.includes(Object.values(LIGHTBOX_PRESETS), presetName) ||
      _.includes(Object.values(STRIPS_PRESETS), presetName)
    )
      return false
    if (await this.hasExceededFormsCount({ hasNewFormRef: true })) {
      const ancestors = await this.boundEditorSDK.components.getAncestors({
        componentRef: newFormRef,
      })
      const widgetRef = ancestors[0]

      this.preventFormAddition(widgetRef)
      return true
    } else return false
  }

  private async _findChildCompByType(ref, type) {
    const children = await this.boundEditorSDK.components.getChildren({ componentRef: ref })

    const childWithTypes = await Promise.all(
      _.map(children, async componentRef => ({
        componentRef,
        type: await this.boundEditorSDK.components.getType({ componentRef }),
      })),
    )

    return _.get(
      _.find(childWithTypes, child => type === child.type),
      'componentRef',
    )
  }

  private async _addForm(
    presetName: FormPresetName,
    {
      containerRef = null,
      targetPageRef = null,
      source = null,
      shouldSelectForm = true,
      createCollection = true,
      width = null,
      space = null,
    }: AddFormPayload = {},
    formSnapshot?: FormSnapshot,
  ): Promise<ComponentRef> {
    const isEditorFlow = this.coreApi.isClassicEditor() || this.coreApi.isResponsive()

    if (isEditorFlow) {
      this.fedopsLogger.interactionStarted('add-form-editor')
    } else {
      this.fedopsLogger.interactionStarted('add-form-adi')
    }

    //TODO remove after spec specs.crm.FormsEditorFetchPresetWithLabels merged
    const formattedPresetName = _.replace(presetName, '-no-labels', '')

    const addFormSource = source || this.coreApi.originEditorType()

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.ADD_FORM_START,
      template: formattedPresetName,
      form_comp_id: null,
      source_name: addFormSource,
    })

    await this._saveSiteIfNecessary(containerRef)
    const locale = await this.boundEditorSDK.info.getLanguage()
    const rawPreset = await fetchPreset(this.ravenInstance)(presetName, locale, failReason =>
      this.coreApi.logFetchPresetsFailed(null, failReason),
    )

    if (!rawPreset) {
      return
    }

    let formRef
    if (isEditorFlow) {
      formRef = await this._addFormEditor({
        formRef: containerRef,
        targetPageRef,
        presetName: formattedPresetName,
        rawPreset,
        selectForm: shouldSelectForm,
        createCollection,
      })
    } else {
      formRef = await this._addFormADI({
        formRef: containerRef,
        presetName: formattedPresetName,
        rawPreset,
        formSnapshot,
        createCustomFields: this.coreApi.isADI(),
        width,
        space,
      })
    }

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.ADD_FORM_COMPLETE,
      template: presetName,
      form_comp_id: await this.coreApi.getFormId(formRef),
      source_name: addFormSource,
    })

    if (isEditorFlow) {
      this.fedopsLogger.interactionEnded('add-form-editor')
    } else {
      this.fedopsLogger.interactionEnded('add-form-adi')
    }

    return formRef
  }

  private async _getFormName(nameFromPreset: string, presetKey: FormPresetName): Promise<string> {
    const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formNames = await Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const componentConnection = await this.coreApi.getComponentConnection(formRef)
        return _.get(componentConnection, 'config.formName', '')
      }),
    )

    return normalizeFormName(formNames, nameFromPreset, presetKey)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }

  private _getPresetsData() {
    return this.coreApi.isResponsive()
      ? RESPONSIVE_PRESET_TYPES
      : _.map(PRESET_TYPES, presetData =>
          _.isString(presetData) ? { preset: presetData } : presetData,
        )
  }

  public loadInitialPanelData() {
    const presetsData = this._getPresetsData()
    return Promise.resolve({ presetsData, presetKey: presetsData[0].preset })
  }

  public async addContactForm(
    contactFormRef: ComponentRef,
    debug: boolean = false,
  ): Promise<ComponentRef> {
    if (!this.coreApi.isClassicEditor()) {
      return
    }

    const {
      components: { serialize, getAncestors, add, remove },
      selection: { selectComponentByCompRef },
    } = this.boundEditorSDK

    const serializedContactForm = await serialize({
      componentRef: contactFormRef,
    })
    const [contactFormFather] = await getAncestors({ componentRef: contactFormRef })

    const formStructure = await this._addContactForm(serializedContactForm, contactFormRef)

    if (formStructure) {
      const componentDefinition = convertPreset(formStructure, {
        controllerId: 'placeholder-id',
        appWidgetStructure: APP_WIDGET_DEFINITION,
      })

      const formRef = await add({
        componentDefinition: <any>componentDefinition,
        pageRef: contactFormFather,
      })

      await selectComponentByCompRef({ compsToSelect: [formRef] })
      if (!debug) {
        // this should be last - if add form failed, we don't want to remove the contact form ref
        await remove({ componentRef: contactFormRef }) // for debugging you can remove this line
      }

      return formRef
    } else {
      console.error(`Contact type '${serializedContactForm.componentType}' not supported`)
    }
  }

  private async _addMissingStructureToSerializedContactForm(
    serializedContactForm: ComponentStructure,
    contactFormRef: ComponentRef,
  ): Promise<DynamicContactForm> {
    let updatedContactForm: DynamicContactForm = <DynamicContactForm>serializedContactForm

    if (!serializedContactForm.mobileStructure) {
      const mobileLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: { id: contactFormRef.id, type: 'MOBILE' },
      })
      if (mobileLayout) {
        updatedContactForm = _.merge({}, updatedContactForm, {
          mobileStructure: {
            layout: mobileLayout,
          },
        })
      } else {
        console.error(`Contact form does not have mobile layout`)
        return null
      }
    }

    if (!_.get(serializedContactForm.style, 'style')) {
      const style = await this.boundEditorSDK.components.style.get({ componentRef: contactFormRef })
      updatedContactForm = _.merge({}, updatedContactForm, {
        style,
        skin: style.skin,
      })
    }

    return updatedContactForm
  }

  private async _addContactForm(
    serializedContactForm: ComponentStructure,
    contactFormRef: ComponentRef,
  ): Promise<RawComponentStructure> {
    const fontOptions: any = await this.boundEditorSDK.theme.fonts.getMap()
    const contactFormWithMissingData = await this._addMissingStructureToSerializedContactForm(
      serializedContactForm,
      contactFormRef,
    )
    const convertedContactForm = convertContactFormToWixForms(
      contactFormWithMissingData,
      fontOptions,
    )
    return this._createDynamicContactFormDataEditor({
      rawPreset: convertedContactForm,
    })
  }

  public async convertAllContactForms(): Promise<void> {
    // this can return a lot of components, this is the fastest i can think of.. let me know if you have any other suggestions
    const componentWithTypes = await Promise.all(
      (await this.boundEditorSDK.components.getAllComponents()).map(async componentRef => {
        const [componentType, style] = await Promise.all([
          this.boundEditorSDK.components.getType({ componentRef }),
          this.boundEditorSDK.components.style.get({ componentRef }),
        ])
        return {
          componentType,
          skin: _.get(style, 'skin'),
          componentRef,
        }
      }),
    )

    const allContactForms = componentWithTypes.filter(component =>
      isSupported(component.componentType, component.skin),
    )

    const numOfConvertedForms: number = await allContactForms.reduce<Promise<number>>(
      async (acc, contactForm) => {
        const nextForm = await this.addContactForm(contactForm.componentRef)
        if (nextForm) {
          return (await acc) + 1
        } else {
          return acc
        }
      },
      Promise.resolve(0),
    )

    if (numOfConvertedForms === allContactForms.length) {
      await this.coreApi.sendAllFormsData()
    } else {
      throw 'could not convert all forms'
    }
  }

  private async _createDynamicContactFormDataEditor({
    rawPreset,
  }: {
    rawPreset: RawComponentStructure
  }): Promise<RawComponentStructure> {
    const createContactFormConfig = async (
      formConfig: ComponentConfig,
    ): Promise<ComponentConfig> => {
      const { emailIds } = await this.remoteApi.convertContactFormEmails({
        encryptedEmails: formConfig.emailIds,
      })
      return {
        ...formConfig,
        msid: await this.coreApi.getMetaSiteId(),
        emailIds,
      }
    }
    const roleEnhancmentMap = {
      [ROLE_FORM]: config => createContactFormConfig(config),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }
}
